export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    contactFetchContacts: '[Contacts] Fetch Contacts',
    contactLoading: '[Contacts] Loading contacts from server',
    contactUpdate: '[Contacts] Update Contact',
    contactSetSelectedContact: '[Contacts] Set Selected Contact',
    contactSetSavedContactDetails: '[Contacts] Set Saved Selected Contact',
    contactUpdateContactsCurrents: '[Contacts] Update Contacts',
    contactChatsGetList: '[Contacts] Get List of Chat Contacts',
    contactSetReadMessage: '[Contacts] Update to cero unread messages',
    contactsFilter: '[Contacts] Get contacts after advanced filter',
    contactsFilterClean: '[Contacts] Clean contacts filter',
    contactSetSearcher: '[Contacts] Get list of contacts from searcher',
    contactCleanList: '[Contacts], Clean list of contacts',
    contactClear: '[Contacts] Clear Contact Details',
    contactSavedContactDetailsClear: '[Contacts] Clear Saved Contact Details',
    getContactsByCategoriesSuccess: '[Contacts] get Contacts By Categories Success',
    getContactsByCategoriesFailure: '[Contacts] get Contacts By Categories Failure',

    botAssignedContactFetch: '[Bot Assigned Contacs] Fetch Contacts assigned to a bot',
    botAssignedContactFetchLoading: '[Bot Assigned Contacs] Loading Contacts assigned to a bot',
    botAssignedContactUpdateListContacts: '[Bot Assigned Contacs] Update List Contacts List',
    botAssignedContactToAssignedAgent: '[Bot Assigned Contacs] Move to Agent Assigned List',

    messagesNewMessage: '[Message] New Messages',
    messageAddFirstContactMessages: '[Message] Set First Contact Messages',
    messagesAddContactMessages: '[Message] Add Contact Messages',
    messagesLoading: '[Message] Fetch Messages',
    messagesSetSelectContactMessages: '[Message] Set Select Contact Messages',
    messageSetContactInfo: '[Message] Set Contact Info',
    messageSendigMessage: '[Message] Sending Message',
    messageUnreadMessages: '[Message] Set unread message mark',
    messageReadMessages: '[Message] Remove unread message mark',
    messageAppendOffsetMessage: '[Message] Append one more to increase offset',
    messageAppendOffsetByMessageId: '[Message] Append one more to increase offset by message id',
    messageUpdateNotification: '[Message] Update notification section info',
    messageRemoveNotification: '[Message] Remove contact of notification section',
    messageAddNotification: '[Message] Add new message on notification section',
    messageClearSelectContactMessages: '[Message] Clear Selected Contact Message',
    messageSetSelectContactMessagesByIdContact: '[Message] Set Selectect Message by Id Contact',
    messageCleanContactList: '[Message] clean Messages by Id Contact',
    MESSAGES_REMOVE_DUPLICATES: '[Message] Remove duplicate messages',

    agentUpdate: '[Agents] Update Agent',
    agentClear: '[Agents] Clear Agent',
    agentGet: '[Agents] Get Agent',

    tagsGet: '[Tags] Get Tags',
    tagsUpdate: '[Tags] Update Tags',

    categoriesFetch: '[Categories] Fetch Categories',
    categoriesFetchSuccess: '[Categories] Success Fetch Categories',
    categoriesFetchFailure: '[Categories] Failure Fetch Categories',
    categoryUpdate: '[Categories] Update Category',
    addCategorySuccess: '[Categories] Success Create Category',
    addCategoryFailure: '[Categories] Failure Create Category',
    updateCategorySuccess: '[Categories] Success Update Category',
    updateCategoryFailure: '[Categories] Failure Update Category',

    groupsNew: '[Groups] Post new group',
    groupsUpdate: '[Groups] Update a group',
    groupsRemove: '[Groups] Delete a group',
    groupsGet: '[Groups] Get all groups',
    groupsSetSelected: '[Groups] Set group selected',

    templatesGet: "[Templates] Set information of templates",
    SHOW_SNACKBAR: "SHOW_SNACKBAR",
    HIDE_SNACKBAR: "HIDE_SNACKBAR"
}