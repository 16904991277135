// Tipos de acciones
export const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';
export const CLEAR_SELECTED_CONTACT = 'CLEAR_SELECTED_CONTACT';
export const SET_OFFSET = 'SET_OFFSET';

// Estado inicial
const initialState = {
    selectedContactId: null,
    offset: 0,
    filters: {
        startDate: '',
        endDate: '',
        searchText: ''
    }
};

// Acciones
export const setSelectedContact = (contactId, offset = 0) => ({
    type: SET_SELECTED_CONTACT,
    payload: { contactId, offset }
});

export const clearSelectedContact = () => ({
    type: CLEAR_SELECTED_CONTACT
});

export const setOffset = (offset) => ({
    type: SET_OFFSET,
    payload: offset
});

// Reducer
export const chatHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_CONTACT:
            return {
                ...state,
                selectedContactId: action.payload.contactId,
                offset: action.payload.offset
            };
        case CLEAR_SELECTED_CONTACT:
            return {
                ...state,
                selectedContactId: null,
                offset: 0
            };
        case SET_OFFSET:
            return {
                ...state,
                offset: action.payload
            };
        default:
            return state;
    }
}; 