import {types} from "../../types/types"

// Estado inicial del reducer
const INITIAL_STATE = {
    contactsMessages: [], // Arreglo de mensajes de contacto
    selectContactMessages: {}, // Mensajes del contacto seleccionado
    infoUnreadMessage: [], // Información sobre los mensajes no leídos
    unreadMessages: 0, // Cantidad de mensajes no leídos
    loading: false, // Indicador de carga
    sending: false // Indicador de envío de mensaje
}

// Reducer para el manejo de mensajes
export const messagesReducer = (state = INITIAL_STATE, action) => {

    const {type, payload} = action;

    switch (type) {

        // Caso especial para la vista de historial de chat que no afecta otras vistas
        case 'LOCAL_CHAT_HISTORY_MESSAGES': {
            // Solo actualiza selectContactMessages sin afectar contactsMessages
            return {
                ...state,
                selectContactMessages: {
                    ...payload,
                    type: 'LOCAL_CHAT_HISTORY_MESSAGES' // Añadimos el tipo para identificarlo
                }
            }
        }

        case types.messagesNewMessage: {
            console.log("messagesNewMessage::payload", payload)
            let {id_contact, _id} = payload
            console.log("messagesNewMessage::id_contact", id_contact)
            console.log("messagesNewMessage::id", _id)
            if (_id === undefined) {
                const chars = '0123456789abcdef';
                _id = '';
                for (let i = 0; i < 24; i++) {
                    const randomIndex = Math.floor(Math.random() * chars.length);
                    _id += chars[randomIndex];
                }
            }
            payload._id = _id
            const agentContacts = state.contactsMessages.filter(c => c.id_contact === id_contact)
            console.log("messagesNewMessage::agentContacts", agentContacts)
            let contact = undefined
            if (agentContacts.length === 0) {
                if (state.selectContactMessages.id_contact === id_contact) {
                    contact = state.selectContactMessages
                } else {
                    return state;// Si no existe en contacMessage regresa el state sin modificar
                }
            }
            if (contact === undefined) {
                contact = state.contactsMessages[0]; //Selecciona los mensajes del contact
            }
            console.log("messagesNewMessage::contact", contact)
            if (contact.hasOwnProperty("messages") === false) return state;
            const messages = contact.messages; // Filtrar el arreglo de mensajes
            console.log("messagesNewMessage::messages", messages)
            
            // Verificar si el mensaje ya existe por ID
            // Verificar si el mensaje ya existe por ID
            const messageById = messages.find(m => m._id === _id);
            
            // Verificar si existe un mensaje similar (mismo contenido, mismo tipo, mismo remitente)
            // dentro de un intervalo de tiempo reciente (30 segundos)
            // Solo verificamos duplicados para mensajes salientes (incoming=false)
            const isDuplicate = !payload.incoming && messages.some(m => {
                // Si es el mismo mensaje por ID, es un duplicado
                if (m._id === _id) return true;
                
                // Solo verificamos duplicados para mensajes salientes (incoming=false)
                if (payload.incoming === true) return false;
                
                // Verificar si es el mismo contenido y tipo
                const sameContent = m.body === payload.body;
                const sameType = m.type_message === payload.type_message;
                const sameDirection = m.incoming === payload.incoming;
                
                // Si no coincide el contenido básico, no es un duplicado
                if (!sameContent || !sameType || !sameDirection) return false;
                
                // Verificar si fue enviado recientemente (dentro de 30 segundos)
                const messageTime = new Date(payload.date_create || Date.now()).getTime();
                const existingMessageTime = new Date(m.date_create || Date.now()).getTime();
                const timeDiff = Math.abs(messageTime - existingMessageTime);
                
                // Si la diferencia de tiempo es menor a 30 segundos, considerarlo duplicado
                return timeDiff < 30000; // 30 segundos en milisegundos
            });
            
            console.log("messagesNewMessage::message", messageById)
            console.log("messagesNewMessage::isDuplicate", isDuplicate)
            
            // Si el mensaje existe por ID o es un duplicado saliente, no modificar el estado
            if (messageById || isDuplicate) {
                return state // Si el mensaje existe regresa el estado sin cambios
            }

            const newconmsg = {
                ...contact,
                messages: [
                    ...messages,
                    payload
                ]
            }
            console.log("messagesNewMessage::newconmsg", newconmsg)

            if (state.selectContactMessages.id_contact === payload.id_contact) {
                return {
                    ...state,
                    contactsMessages: [
                        ...state.contactsMessages.filter(cm => cm.id_contact !== id_contact),
                        newconmsg
                    ],
                    selectContactMessages: {...newconmsg}
                }
            }
            return {
                ...state,
                contactsMessages: [
                    ...state.contactsMessages.filter(cm => cm.id_contact !== id_contact),
                    newconmsg
                ]
            }
        }
        case
        types.messageAddFirstContactMessages
        :
            // Acción para agregar los mensajes del primer contacto
            return {
                ...state,
                contactsMessages: [...state.contactsMessages, payload],
                selectContactMessages: {...payload}
            }
        case
        types.messagesAddContactMessages
        : {
            // Acción para agregar mensajes de un contacto
            const index = state.contactsMessages.findIndex(contact => contact.id_contact === payload.id_contact);
            const new_contactsMessages = [...state.contactsMessages];
            new_contactsMessages[index] = {
                ...payload,
                messages: [
                    ...payload.messages,
                    ...new_contactsMessages[index].messages
                ]
            }
            return {
                ...state,
                contactsMessages: new_contactsMessages,
                selectContactMessages: {
                    ...state.selectContactMessages,
                    ...payload,
                    messages: [
                        ...payload.messages,
                        ...state.selectContactMessages.messages
                    ]
                }
            }
        }
        case
        types.messagesLoading
        :
            // Acción para indicar el estado de carga
            return {
                ...state,
                loading: payload
            }
        case
        types.messagesSetSelectContactMessages
        :
            // Acción para establecer los mensajes del contacto seleccionado
            return {
                ...state,
                selectContactMessages: {...payload}
            }
        case
        types.messageSetContactInfo
        :
            // Acción para establecer la información del contacto
            return {
                ...state,
                selectContactMessages: {...payload}
            }
        case
        types.messageSendigMessage
        :
            // Acción para indicar el estado de envío de mensaje
            return {
                ...state,
                sending: payload
            }
        case
        types.messageUnreadMessages
        : {
            // Acción para marcar mensajes como no leídos
            const {id_contact} = payload;

            const index = state.contactsMessages.findIndex(c => c.id_contact === id_contact); // Busca el índice del objeto con el arreglo de mensajes

            if (index === -1) return state; // Si no existe en contactsMessages, regresa el estado sin modificar

            const contactMessage = state.contactsMessages[index]; // Selecciona los mensajes del contacto

            contactMessage.messages = [...state.contactsMessages[index].messages, 'new_messages']; // Agrega el marcador 'new_messages'

            if (state.selectContactMessages.id_contact === id_contact) {
                return {
                    ...state,
                    contactsMessages: [
                        ...state.contactsMessages.filter(cm => cm.id_contact !== id_contact),
                        contactMessage
                    ],
                    selectContactMessages: {...contactMessage}
                }
            }

            return {
                ...state,
                contactsMessages: [
                    ...state.contactsMessages.filter(cm => cm.id_contact !== id_contact),
                    contactMessage
                ]
            }
        }
        case
        types.messageReadMessages
        : {
            // Acción para marcar mensajes como leídos
            const {id_contact} = payload;

            const index = state.contactsMessages.findIndex(c => c.id_contact === id_contact); // Busca el índice del objeto con el arreglo de mensajes

            if (index === -1) return state; // Si no existe en contactsMessages, regresa el estado sin modificar

            const contactMessage = state.contactsMessages[index]; // Selecciona los mensajes del contacto

            const new_msg = contactMessage.messages.find(item => item === 'new_messages');

            if (!new_msg) return state;

            contactMessage.messages = [...state.contactsMessages[index].messages.filter(cm => cm !== 'new_messages')]; // Quita el marcador 'new_messages'

            if (state.selectContactMessages.id_contact === id_contact) {
                return {
                    ...state,
                    contactsMessages: [
                        ...state.contactsMessages.filter(cm => cm.id_contact !== id_contact),
                        contactMessage
                    ],
                    selectContactMessages: {...contactMessage}
                }
            }

            return {
                ...state,
                contactsMessages: [
                    ...state.contactsMessages.filter(cm => cm.id_contact !== id_contact),
                    contactMessage
                ]
            }
        }
        case
        types.messageAppendOffsetMessage
        : {
            // Acción para actualizar el offset de un mensaje
            const index = state.contactsMessages.findIndex(c => c.id_contact === payload);
            const contactMessages = [...state.contactsMessages];

            contactMessages[index] = {
                ...contactMessages[index],
                offset: (contactMessages[index].offset + 1)
            }

            if (payload === state.selectContactMessages.id_contact) {
                return {
                    ...state,
                    contactsMessages: contactMessages,
                    selectContactMessages: {
                        ...state.selectContactMessages,
                        offset: (state.selectContactMessages.offset + 1)
                    }
                }
            }

            return {
                ...state,
                contactsMessages: contactMessages
            }
        }
        case types.messageAppendOffsetByMessageId: {
            console.log("messageAppendOffsetByMessageId")
            // Acción para actualizar el offset por ID de mensaje
            const {id_contact, id_message} = payload;

            const index = state.contactsMessages.findIndex(c => c.id_contact === id_contact);
            const contactMessages = [...state.contactsMessages];

            if (index !== -1 && contactMessages[index] && contactMessages[index].messages) {
                const index_msg = contactMessages[index].messages.findIndex(c => c._id === id_message);

                if (index_msg !== -1) {
                    return state;
                }

                contactMessages[index] = {
                    ...contactMessages[index],
                    offset: contactMessages[index].offset + 1
                };

                if (id_contact === state.selectContactMessages.id_contact) {
                    return {
                        ...state,
                        contactsMessages: contactMessages,
                        selectContactMessages: {
                            ...state.selectContactMessages,
                            offset: state.selectContactMessages.offset + 1
                        }
                    };
                }

                return {
                    ...state,
                    contactsMessages: contactMessages
                };
            }
            // Manejo del caso donde `index` es `-1` o `contactMessages[index]` es `undefined`
            console.error('Invalid contact index or messages not found');
            return state;
        }

        case
        types.messageUpdateNotification
        : {
            // Acción para actualizar las notificaciones de mensajes
            const {count, items} = payload;

            return {
                ...state,
                unreadMessages: count,
                infoUnreadMessage: [...items]
            }
        }
        case
        types.messageRemoveNotification
        : {
            // Acción para eliminar una notificación de mensaje
            const contact = state.infoUnreadMessage.find(contact => contact._id === payload);

            if (!contact) return state;

            return {
                ...state,
                unreadMessages: (state.unreadMessages - contact.unread_message),
                infoUnreadMessage: [...state.infoUnreadMessage.filter(c => c._id !== payload)]
            }
        }
        case
        types.messageAddNotification
        : {
            // Acción para agregar una notificación de mensaje
            const contactIndex = state.infoUnreadMessage.findIndex(contact => contact._id === payload._id);

            if (contactIndex !== -1) {
                return {
                    ...state,
                    infoUnreadMessage: [
                        payload,
                        ...state.infoUnreadMessage.filter(c => c._id !== payload._id)
                    ],
                    unreadMessages: (state.unreadMessages + 1)
                };
            }

            return {
                ...state,
                infoUnreadMessage: [
                    payload,
                    ...state.infoUnreadMessage
                ],
                unreadMessages: (state.unreadMessages + 1)
            }
        }
        case
        types.messageClearSelectContactMessages
        : {
            // Acción para limpiar los mensajes del contacto seleccionado
            return {
                ...state,
                selectContactMessages: {}
            }
        }
        case
        types.messageSetSelectContactMessagesByIdContact
        : {
            // Acción para establecer el contacto seleccionado por ID de contacto
            const contact = state.contactsMessages.find(item => item.id_contact === payload);

            return {
                ...state,
                selectContactMessages: contact
            }
        }
        case
        types.messageCleanContactList
        : {
            return {
                ...state,
                contactsMessages: []
            }
        }
        default:
            return state;
    }
}
