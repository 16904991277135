// Import de assignedTypes desde "./assignedTypes"
import {assignedTypes} from "./assignedTypes";

// Estado inicial del reducer
const INITIAL_STATE = {
    loading_contacts: false,
    selectAgentContact: null,
    contactsAssigneds: [],
    IdContactMoved: null
}

// Reducer para el manejo de los contactos asignados
export const assignedContactsReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        // Caso para obtener los contactos asignados
        case assignedTypes.getContacts: {
            // Obtengo solo los contactos asignados del estado
            const {contactsAssigneds} = state;

            // Obtengo la lista de contactos del agente a actualizar
            let agent_contacts = contactsAssigneds.find(contacts => contacts._id === payload._id);

            if (!agent_contacts) {
                // Si el agente no tiene contactos asignados, se agrega la lista de contactos recibida
                return {
                    ...state,
                    contactsAssigneds: [
                        ...state.contactsAssigneds,
                        payload
                    ]
                }
            }

            // Genero la nueva lista de contactos asignados al agente
            agent_contacts = {
                ...payload,
                contacts: [
                    ...agent_contacts.contacts,
                    ...payload.contacts
                ]
            }

            // Filtrar el agente que se está actualizando
            const filterContactsAgents = contactsAssigneds.filter(contacts => contacts._id !== payload._id)

            return {
                ...state,
                contactsAssigneds: [
                    ...filterContactsAgents,
                    agent_contacts
                ]
            }
        }
        // Caso para mover un contacto de un agente a otro
        case assignedTypes.moveContact: {
            // Extraer los datos necesarios del payload
            const {id_contact, id_agent_source, id_agent_target} = payload;
            // Encontrar el agente fuente y el agente destino en la lista de contactos asignados
            let agentSource = state.contactsAssigneds.find(agent => agent._id === id_agent_source);
            let agentTarget = state.contactsAssigneds.find(agent => agent._id === id_agent_target);

            // Encontrar el contacto que se va a mover en los contactos del agente fuente
            const contactToMove = agentSource.contacts.find(contact => contact._id === id_contact);

            // Actualizar el id del agente del contacto que se mueve
            contactToMove.id_agent = id_agent_target;

            // Crear una nueva lista de contactos del agente fuente sin el contacto movido
            const newAgentContactsSource = agentSource.contacts.filter(contact => contact._id !== id_contact);

            // Actualizar el agente fuente con la nueva lista de contactos y el offset correspondiente
            agentSource = {
                ...agentSource,
                contacts: newAgentContactsSource,
                offset: agentSource.offset !== 0 ? (agentSource.offset - 1) : 0
            }

            // Filtrar los agentes diferentes al agente fuente y al agente destino
            const newAgents = state.contactsAssigneds.filter(agent => agent._id !== id_agent_source && agent._id !== id_agent_target);

            // Si el agente destino no existe, se retorna el estado con el agente fuente y los nuevos agentes
            if (!agentTarget) {
                return {
                    ...state,
                    contactsAssigneds: [
                        agentSource,
                        ...newAgents
                    ],
                    IdContactMoved: id_contact
                };
            }

            // Actualizar el agente destino con el contacto movido y su nueva lista de contactos
            agentTarget = {
                ...agentTarget,
                contacts: [
                    contactToMove,
                    ...agentTarget.contacts
                ],
                offset: (agentTarget.offset + 1)
            }

            // Retornar el estado con el agente fuente, el agente destino y los nuevos agentes
            return {
                ...state,
                contactsAssigneds: [
                    agentSource,
                    agentTarget,
                    ...newAgents
                ],
                IdContactMoved: id_contact
            }
        }
        // Caso para indicar si se están cargando los contactos asignados
        case assignedTypes.loadingContacts: {
            return {
                ...state,
                loading_contacts: payload
            }
        }
        // Caso para limpiar el Id del contacto movido
        case assignedTypes.clearIdContact: {
            return {
                ...state,
                IdContactMoved: null
            }
        }
        // Caso para agregar un contacto a un agente
        case assignedTypes.addContact: {
            // Obtener solo los contactos asignados del estado
            const {contactsAssigneds} = state;

            // Obtener la lista de contactos del agente a actualizar
            let agent_contacts = contactsAssigneds.find(agent => agent._id === payload.id_agent);

            if (!agent_contacts) {
                return state;
            }

            // Actualizar el agente con el nuevo contacto y la lista actualizada de contactos
            agent_contacts = {
                ...agent_contacts,
                contacts: [
                    payload,
                    ...agent_contacts.contacts
                ],
                offset: (agent_contacts.offset + 1)
            }

            // Filtrar los agentes diferentes al agente actualizado
            const agents = contactsAssigneds.filter(agent => agent._id !== payload.id_agent);

            // Retornar el estado con el agente actualizado y los demás agentes
            return {
                ...state,
                contactsAssigneds: [
                    ...agents,
                    agent_contacts
                ]
            }
        }
        // Caso para actualizar un contacto existente
        case assignedTypes.updateContact: {
            if (payload.id_agent === undefined) {
                payload.id_agent = null
            }
            // Encontrar el agente al que pertenece el contacto a actualizar
            let currentAgent = state.contactsAssigneds.find(agent => agent._id === payload.id_agent);
            console.log("assignedTypes.updateContact::currentAgent", currentAgent)

            // Filtrar los contactos del agente actual, excluyendo el contacto que se va a actualizar
            if (currentAgent) {
                const currentContacts = currentAgent.contacts.filter(contact => contact._id !== payload._id);
                // Actualizar el agente con el contacto actualizado y la lista de contactos actualizada
                const newUpdateAgent = {
                    ...currentAgent,
                    contacts: [
                        payload,
                        ...currentContacts
                    ]
                }

                // Filtrar los agentes diferentes al agente actualizado
                const agentes = state.contactsAssigneds.filter(agent => agent._id !== payload.id_agent);
                // Retornar el estado con el agente actualizado y los demás agentes
                return {
                    ...state,
                    contactsAssigneds: [
                        newUpdateAgent,
                        ...agentes
                    ]
                }
            }
            return state;
        }
        // Caso para eliminar un contacto
        case assignedTypes.removeContact: {
            console.log("assignedTypes.removeContact::state.contactsAssigneds", state.contactsAssigneds)
            console.log(payload)
            
            // Verificar si contactsAssigneds existe y no está vacío
            if (!state.contactsAssigneds || state.contactsAssigneds.length === 0) {
                console.warn("No hay contactos asignados para eliminar");
                return state;
            }
            
            // Verificar si el payload tiene la propiedad id_agent
            if (!payload || !payload.id_agent) {
                console.warn("El contacto no tiene id_agent", payload);
                return state;
            }
            
            // Encontrar el agente al que pertenece el contacto que se va a eliminar
            const currentAgent = state.contactsAssigneds.find(agent => agent._id === payload.id_agent);
            
            // Si no se encuentra el agente, retornar el estado sin cambios
            if (!currentAgent) {
                console.warn(`No se encontró el agente con ID ${payload.id_agent}`);
                return state;
            }
            
            // Verificar si el agente tiene la propiedad contacts
            if (!currentAgent.contacts) {
                console.warn(`El agente con ID ${payload.id_agent} no tiene contactos`);
                return state;
            }
            
            // Filtrar los contactos del agente actual, excluyendo el contacto que se va a eliminar
            const currentContacts = currentAgent.contacts.filter(contact => contact._id !== payload._id);

            // Actualizar el agente con la lista de contactos actualizada y el offset correspondiente
            const newUpdateAgent = {
                ...currentAgent,
                contacts: [
                    ...currentContacts
                ],
                offset: currentAgent.offset !== 0 ? (currentAgent.offset - 1) : 0
            }

            // Filtrar los agentes diferentes al agente actualizado
            const agentes = state.contactsAssigneds.filter(agent => agent._id !== payload.id_agent);

            // Retornar el estado con el agente actualizado y los demás agentes
            return {
                ...state,
                contactsAssigneds: [
                    newUpdateAgent,
                    ...agentes
                ]
            }
        }
        // Caso para marcar un contacto
        case assignedTypes.markContact: {
            const {contact, hasMark} = payload;

            // Encontrar el agente al que pertenece el contacto que se va a marcar
            let currentAgent = state.contactsAssigneds.find(agent => agent._id === contact.id_agent);

            if (currentAgent) {
                // Encontrar el índice del contacto que se va a marcar en la lista de contactos del agente
                const indexContactToMark = currentAgent.contacts.findIndex(contactMark => contactMark._id === contact._id);

                // Obtener el contacto a marcar y actualizar su propiedad "hasMark"
                let contactToMark = currentAgent.contacts[indexContactToMark]
                contactToMark = {
                    ...contactToMark,
                    hasMark
                }
                currentAgent.contacts[indexContactToMark] = contactToMark;

                // Filtrar los agentes diferentes al agente actualizado
                const agents = state.contactsAssigneds.filter(agent => agent._id !== contact.id_agent);

                // Retornar el estado con el agente actualizado y los demás agentes
                return {
                    ...state,
                    contactsAssigneds: [
                        ...agents,
                        currentAgent
                    ]
                }
            }
            return state;
        }
        case 'UPDATE_CONTACTS_WITH_CATEGORY': {
            const updatedContactsAssigneds = state.contactsAssigneds.map(agent => {

                return {
                    ...agent,
                    contacts: agent.contacts.map(contact => {
                        const updatedContact = payload && Array.isArray(payload)
                            ? payload.find(updated => updated._id === contact._id)
                            : null;
                        return updatedContact ? {...contact, categories: updatedContact.categories} : contact;
                    })
                };
            });
            return {
                ...state,
                contactsAssigneds: updatedContactsAssigneds
            };
        }
        default: {
            return state;
        }
    }
}
