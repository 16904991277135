import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './App';
import {Store} from './store/store';
import Spinner from './components/spinner/Spinner';

import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {AppInsightsContextProvider} from './ApplicationInsightsProvider';
import * as Sentry from "@sentry/react";
import {DevSupport} from "@react-buddy/ide-toolbox";


// MSAL configuration
const configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTH,
        redirectUri: process.env.REACT_APP_URI_REDIRECTION,
        postLogoutRedirectUri: process.env.REACT_APP_URI_REDIRECTION
    }
}

const pca = new PublicClientApplication(configuration)

Sentry.init({
    dsn: "https://c6c3f79ea37c386c2c9b10a0eee668b5@o213473.ingest.us.sentry.io/4506866967314432",
    integrations: [],
});
// Component
const AppProvider = () => {

    return (
        <AppInsightsContextProvider>
            <MsalProvider instance={pca}>
                <Provider store={Store}>
                    <Suspense fallback={<Spinner/>}>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </Suspense>
                </Provider>
            </MsalProvider>
        </AppInsightsContextProvider>
    );
}

ReactDOM.render(
    <AppProvider/>,
    document.getElementById('root'),
);
