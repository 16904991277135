import {client} from '../axios/client';
import {types} from "../types/types";
import {v4 as uuidv4} from "uuid";

// Envía un mensaje de WhatsApp de texto
/**
 * Función para enviar un mensaje de WhatsApp
 * @param {object} message - Los datos del mensaje a enviar
 * @param {string} phone_customer - Número de teléfono del cliente
 * @param {string} phone_contact - Número de teléfono del contacto
 * @param {string} appName - Nombre de la aplicación
 * @param {string} token - Token de autorización
 * @param {string} id_agent - ID del agente
 */
export const sendMessageWhatsapp = (message, phone_customer, phone_contact, appName, token, id_agent) => {
    console.log("sendMessageWhatsapp::message", message)
    const {v4: uuidv4} = require('uuid');
    const uuid = uuidv4();


    return (dispatch) => {
        message.id_agent = id_agent
        message._id = uuid
        dispatch(messagesNewMessage(message));
        dispatch(messageReadMessage(message.id_contact));
        // Actualiza el offset del mensaje
        dispatch(messageAppendOffsetMessage(message.id_contact));
        // Remueve la notificación del contacto
        dispatch(messageRemoveNotification(message.id_contact));
        // Realiza una petición POST para enviar el mensaje a través del cliente HTTP
        client.post(`/messages/${phone_customer}/${phone_contact}`, message, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                app: appName,
                id_agent
            }
        })
            .then(({data}) => {
                console.log("sendMessageWhatsapp:data", data)
            })
            .catch(e => console.log(e));
    }
}


// Envía un mensaje de WhatsApp con medios (imágenes, archivos adjuntos, etc.)
/**
 * Envía un mensaje multimedia a través de WhatsApp
 * @param {FormData} formData - FormData con el archivo a enviar
 * @param {string} phone - Número de teléfono del agente
 * @param {string} id_contact - ID del contacto
 * @param {string} phone_contact - Número de teléfono del contacto
 * @param {string} name - Nombre del agente
 * @param {string} token - Token de acceso
 * @param {string} id_agent - ID del agente
 * @param {Function} successCallback - Función a ejecutar después de enviar el mensaje con éxito
 * @returns {Function} - Función thunk
 */
export const sendMediaMessage = (formData, phone, id_contact, phone_contact, name, token, id_agent, successCallback = null) => {
    // console.log("sendMediaMessage: Iniciando envío de mensaje multimedia");
    // console.log("sendMediaMessage: Parámetros:", { phone, id_contact, phone_contact, name, id_agent });
    
    // Verificar el contenido del FormData
    // console.log("sendMediaMessage: Verificando contenido del FormData");
    try {
        for (let pair of formData.entries()) {
            // console.log("sendMediaMessage: FormData entry", pair[0], pair[1]);
        }
    } catch (error) {
        console.error("sendMediaMessage: Error al leer FormData", error);
    }
    
    return (dispatch) => {
        // Indicar que se está enviando un mensaje
        dispatch(messageSending(true));
        
        // Construir la URL correctamente
        const url = `/messages/upload/${phone}/${phone_contact}`;
        // console.log("sendMediaMessage:url", url);
        
        // Configurar los parámetros de la solicitud
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
                // No establecer Content-Type, Axios lo hará automáticamente para FormData
            },
            params: {
                name,
                id_agent
            }
        };
        
        // console.log("sendMediaMessage:config", config);
        
        // Realiza una petición POST para enviar el mensaje de medios a través del cliente HTTP
        client.post(url, formData, config)
            .then(response => {
                // console.log("sendMediaMessage:success response", response);
                const responseData = response.data;
                // console.log("sendMediaMessage:success data", responseData);
                
                // Actualiza el estado de la aplicación con el nuevo mensaje enviado
                dispatch(messagesNewMessage(responseData));
                // Marca el mensaje como leído
                dispatch(messageReadMessage(responseData.id_contact));
                // Actualiza el offset del mensaje
                dispatch(messageAppendOffsetMessage(responseData.id_contact));
                // Remueve la notificación del contacto
                dispatch(messageRemoveNotification(responseData.id_contact));
                // Eliminar posibles duplicados
                dispatch(removeDuplicateMessages(responseData.id_contact));
                // Indicar que se ha completado el envío del mensaje
                dispatch(messageSending(false));
                
                // Ejecutar la función de callback de éxito si se proporciona
                if (successCallback) {
                    successCallback(responseData);
                }
            })
            .catch(error => {
                console.error('Error al enviar mensaje multimedia:', error);
                // Desactivar el indicador de carga en caso de error
                dispatch(messageSending(false));
            });
    }
}

/**
 * Obtiene los mensajes de un contacto por su ID
 * @param {string} id_contact - ID del contacto
 * @param {string} token - Token de autorización
 * @param {object} source - Objeto fuente de cancelación de la petición
 * @param {number} new_message - Cantidad de nuevos mensajes
 * @param {number} offset - Offset de los mensajes
 * @param {number} limit - Límite de mensajes a obtener
 */
export const getContactMessagesByIdContact = (id_contact, token, source, new_message = 0, offset = 0, limit = 10) => {
    return (dispatch) => {
        // Si el offset es 0, se establece la información del contacto en el estado
        if (offset === 0) dispatch(messageSetContactInfo(id_contact));

        // Realiza una petición GET para obtener los mensajes del contacto
        client.get(`/messages/${id_contact}`, {
            params: {
                offset,
                limit
            },
            headers: {'Authorization': `Bearer ${token}`},
            cancelToken: source.token
        })
            .then(({data}) => {
                console.log("getContactMessagesByIdContact::messages", data)
                const {items, count} = data;

                // Construye un objeto con la información de los mensajes del contacto
                const contactMessages = {
                    id_contact,
                    messages: [
                        ...items.reverse()
                    ],
                    count,
                    offset,
                    limit,
                    new_message
                };

                if (offset === 0) {
                    // Si el offset es 0, se agregan los mensajes al estado como los primeros mensajes del contacto
                    if (new_message > 0) {
                        contactMessages.messages.splice(-new_message, 0, "new_messages");
                    }
                    dispatch(messageCleanListByIdContact())
                    dispatch(messageAddFirstContactMessages(contactMessages));
                } else {
                    // Si el offset no es 0, se agregan los mensajes al estado como mensajes adicionales del contacto
                    dispatch(messagesAddContactMessages(contactMessages));
                }
            })
            .catch(e => console.log(e));
    }
}// Obtiene los mensajes de un contacto por su ID
/**
 * Función para obtener los mensajes de un contacto por su ID y actualizarlos
 * @param {string} id_contact - ID del contacto
 * @param {string} token - Token de autorización
 * @param {object} source - Objeto fuente de cancelación de la petición
 * @param {number} new_message - Cantidad de nuevos mensajes
 * @param {number} offset - Offset de los mensajes
 * @param {number} limit - Límite de mensajes a obtener
 */
export const updateContactMessagesByIdContact = (id_contact, token, source, new_message = 0, offset = 0, limit = 10) => {
    return (dispatch) => {
        // Si el offset es 0, se establece la información del contacto en el estado
        if (offset === 0) dispatch(messageSetContactInfo(id_contact));

        // Realiza una petición GET para obtener los mensajes del contacto
        client.get(`/messages/${id_contact}`, {
            params: {
                offset,
                limit
            },
            headers: {'Authorization': `Bearer ${token}`},
            cancelToken: source.token
        })
            .then(({data}) => {
                console.log("getContactMessagesByIdContact::messages", data)
                dispatch(messageCleanListByIdContact())
                const {items, count} = data;

                // Construye un objeto con la información de los mensajes del contacto
                const contactMessages = {
                    id_contact,
                    messages: [
                        ...items.reverse()
                    ],
                    count,
                    offset,
                    limit,
                    new_message
                };

                if (offset === 0) {
                    // Si el offset es 0, se agregan los mensajes al estado como los primeros mensajes del contacto
                    if (new_message > 0) {
                        contactMessages.messages.splice(-new_message, 0, "new_messages");
                    }
                    dispatch(messageAddFirstContactMessages(contactMessages));
                } else {
                    // Si el offset no es 0, se agregan los mensajes al estado como mensajes adicionales del contacto
                    dispatch(messagesAddContactMessages(contactMessages));
                }
            })
            .catch(e => console.log(e));
    }
}

/**
 * Obtiene el recuento de mensajes no leídos para un cliente
 * @param {string} id_customer - ID del cliente
 * @param {string} token - Token de autorización
 */
export const getUnreadMessageCount = (id_customer, token) => {
    return (dispatch) => {
        // Realiza una petición GET para obtener el recuento de mensajes no leídos
        client.get(`/messages/unread/${id_customer}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(({data}) => {
                // Actualiza la notificación de mensajes no leídos en el estado de la aplicación
                dispatch(messageUpdateNotification(data.count, data.items));
            });
    }
}


/**
 * Envía un mensaje de WhatsApp utilizando una plantilla predefinida
 * @param {object} message - Datos del mensaje y la plantilla
 * @param {string} phone_customer - Número de teléfono del cliente
 * @param {string} phone_contact - Número de teléfono del contacto
 * @param {string} token - Token de autorización
 * @param {string} id_agent - ID del agente
 */
export const sendTemplateWhatsapp = (message, phone_customer, phone_contact, token, id_agent) => {
    const {v4: uuidv4} = require('uuid');
    const uuid = uuidv4();
    console.log("sendTemplateWhatsapp::message", message)
    return (dispatch) => {
        if (message.type_message === "image") {
            dispatch(messagesNewMessage({
                "_id": uuid,
                "type_message": "text",
                "body": "Se ha enviado un archivo   ",
                "incoming": false,
                "id_contact": message.id_contact,
                "id_agent": id_agent,
                "message_provider": "gushup"
            }));
        } else {
            message.id_agent = id_agent
            message._id = uuid
            dispatch(messagesNewMessage(message));
            dispatch(messageReadMessage(message.id_contact));
            // Actualiza el offset del mensaje
            dispatch(messageAppendOffsetMessage(message.id_contact));
            // Remueve la notificación del contacto
            dispatch(messageRemoveNotification(message.id_contact));
        }


        console.log("sendTemplateWhatsapp::message", message)
        // Realiza una petición POST para enviar el mensaje de WhatsApp utilizando una plantilla
        client.post(`/messages/template/${phone_customer}/${phone_contact}`, message, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                id_agent
            }
        })
            .then(({data}) => {
                console.log("sendTemplateWhatsapp::data", data)
                if (data.type_message === "image") {
                    dispatch(messagesNewMessage(data));
                    dispatch(messageReadMessage(message.id_contact));
                    // Actualiza el offset del mensaje
                    dispatch(messageAppendOffsetMessage(message.id_contact));
                    // Remueve la notificación del contacto
                    dispatch(messageRemoveNotification(message.id_contact));
                }
            })
            .catch(e => console.log(e));
    }
}


// Acciones para actualizar el estado de la aplicación
/**
 * Crea una acción para indicar que se está enviando un mensaje
 * @param {any} payload - Datos del mensaje que se está enviando
 * @returns {object} - Acción de mensaje enviando
 */
export const messageSending = (payload) => ({
    type: types.messageSendigMessage,
    payload: payload
})


/**
 * Crea una acción para añadir un nuevo mensaje
 * @param {object} newmessage - Nuevo mensaje a añadir
 * @returns {object} - Acción de nuevo mensaje
 */
export const messagesNewMessage = (newmessage) => ({
    type: types.messagesNewMessage,
    payload: newmessage
})

/**
 * Crea una acción para añadir los primeros mensajes de un contacto
 * @param {object} contactMessages - Mensajes del contacto
 * @returns {object} - Acción de primeros mensajes de contacto
 */
export const messageAddFirstContactMessages = (contactMessages) => ({
    type: types.messageAddFirstContactMessages,
    payload: contactMessages
})

/**
 * Crea una acción para establecer los mensajes seleccionados de un contacto
 * @param {object} selectContactMessage - Mensajes seleccionados del contacto
 * @returns {object} - Acción de mensajes seleccionados de contacto
 */
export const messagesSetSelectContactMessage = (selectContactMessage) => ({
    type: types.messagesSetSelectContactMessages,
    payload: selectContactMessage
})

/**
 * Crea una acción para añadir mensajes de un contacto existente
 * @param {object} contactMessages - Mensajes del contacto
 * @returns {object} - Acción de añadir mensajes de contacto
 */
export const messagesAddContactMessages = (contactMessages) => ({
    type: types.messagesAddContactMessages,
    payload: contactMessages
});

/**
 * Crea una acción para indicar el estado de carga de mensajes
 * @param {boolean} loading - Estado de carga de mensajes
 * @returns {object} - Acción de carga de mensajes
 */
export const messagesLoading = (loading) => ({
    type: types.messagesLoading,
    payload: loading
})

/**
 * Crea una acción para establecer la información de contacto
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción de establecer información de contacto
 */
export const messageSetContactInfo = (id_contact) => ({
    type: types.messageSetContactInfo,
    payload: {
        id_contact
    }
})

/**
 * Crea una acción para indicar que hay mensajes no leídos en un contacto
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción de mensajes no leídos
 */
export const messageUnreadMessage = (id_contact) => ({
    type: types.messageUnreadMessages,
    payload: {
        id_contact
    }
})

/**
 * Crea una acción para indicar que se han leído los mensajes de un contacto
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción de mensajes leídos
 */
export const messageReadMessage = (id_contact) => ({
    type: types.messageReadMessages,
    payload: {
        id_contact
    }
})

/**
 * Crea una acción para añadir un desplazamiento a los mensajes de un contacto
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción de añadir desplazamiento a mensajes
 */
export const messageAppendOffsetMessage = (id_contact) => ({
    type: types.messageAppendOffsetMessage,
    payload: id_contact
})

/**
 * Crea una acción para añadir un desplazamiento a los mensajes de un contacto utilizando el ID del mensaje
 * @param {string} id_contact - ID del contacto
 * @param {string} id_message - ID del mensaje
 * @returns {object} - Acción de añadir desplazamiento por ID de mensaje
 */
export const messageAppendOffsetByMessageId = (id_contact, id_message) => ({
    type: types.messageAppendOffsetByMessageId,
    payload: {
        id_contact,
        id_message
    }
})

/**
 * Crea una acción para actualizar las notificaciones de mensajes
 * @param {number} count - Cantidad de mensajes no leídos
 * @param {array} items - Ítems de mensajes no leídos
 * @returns {object} - Acción de actualizar notificaciones de mensajes
 */
export const messageUpdateNotification = (count, items) => ({
    type: types.messageUpdateNotification,
    payload: {
        count,
        items
    }
})

/**
 * Crea una acción para eliminar la notificación de un contacto
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción de eliminar notificación de contacto
 */
export const messageRemoveNotification = (id_contact) => ({
    type: types.messageRemoveNotification,
    payload: id_contact
})

/**
 * Crea una acción para añadir una notificación de mensaje a un contacto
 * @param {object} contact - Contacto
 * @returns {object} - Acción de añadir notificación de mensaje
 */
export const messageAddNotification = (contact) => ({
    type: types.messageAddNotification,
    payload: contact
});

/**
 * Crea una acción para limpiar los mensajes seleccionados de un contacto
 * @returns {object} - Acción de limpiar mensajes seleccionados de contacto
 */
export const messageClearSelectContactMessages = () => ({
    type: types.messageClearSelectContactMessages
})

/**
 * Crea una acción para establecer los mensajes seleccionados de un contacto mediante su ID
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción de establecer mensajes seleccionados de contacto por ID de contacto
 */
export const messageSetSelectContactMessagesByIdContact = (id_contact) => ({
    type: types.messageSetSelectContactMessagesByIdContact,
    payload: id_contact
})
export const messageCleanListByIdContact = () => ({
    type: types.messageCleanContactList,
    payload: []
})

/**
 * Crea una acción para eliminar mensajes duplicados de un contacto específico
 * @param {string} id_contact - ID del contacto
 * @returns {object} - Acción para eliminar mensajes duplicados
 */
export const removeDuplicateMessages = (id_contact) => ({
    type: types.MESSAGES_REMOVE_DUPLICATES,
    payload: id_contact
})
