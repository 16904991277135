import {TableBody} from '@mui/material';
import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import {element, exact} from 'prop-types';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Unauthorized = Loadable(lazy(() => import('../views/authentication/Unauthorized')));

/* ****Pages***** */
const Home = Loadable(lazy(() => import('../views/dashboards/Home')));
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
const TableHeadContacts = Loadable(lazy(() => import('../views/contacts/TableHeadFilter')));
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard'))); // Añadi la pagina de dashboard jr010020
const Chat = Loadable(lazy(() => import('../views/chat/Chat')));
const ContactsDetails = Loadable(lazy(() => import('../views/contacts/ContactsDetails')));
const Groups = Loadable(lazy(() => import('../views/groups/Groups')));
const Filter = Loadable(lazy(() => import('../views/groups/AdvancedFilters')));
const Templates = Loadable(lazy(() => import('../views/templates/Templates')));
const Categories = Loadable(lazy(() => import('../views/categories/Categories')));
const Amplead = Loadable(lazy(() => import('../views/Amplead/Dashboard')));
const HelloWorld = Loadable(lazy(() => import("../views/HelloWorld")));
const ChatView = Loadable(lazy(() => import('../views/chat/ChatView')));
const ChatHistory = Loadable(lazy(() => import('../views/chat/ChatHistory')));

const childrenRoutes = [
    {path: '/', element: <Navigate to="/auth/login"/>},
    {path: '/admin/home', exact: true, element: <Navigate to="/admin/chat"/>},
    {path: '/admin/chat', exact: true, element: <Chat/>},
    {path: '/admin/contacts', element: <TableHeadContacts/>},
    {path: '/admin/contacts/:contactId', exact: true, element: <ContactsDetails/>},
    {path: '/admin/groups', exact: true, element: <Groups/>},
    {path: '/admin/groups/newGroup', exact: true, element: <Filter/>},
    {path: '/admin/templates', exact: true, element: <Templates/>},
    {path: '/admin/categories', exact: true, element: <Categories/>},
    {path: '/admin/chatview/:chatId', exact: true, element: <ChatView/>, toSave: true},
    {path: '/admin/amplead', exact: true, element: <Amplead/>},
    {path: '/admin/ampleadid/:sourceId', exact: true, element: <HelloWorld/>, toSave: true},
    {path: '/admin/chat/history', exact: true, element: <ChatHistory/>, toSave: true},
    {path: '/admin/dashboard', exact: true, element: <Dashboard/>}, // Añadi la ruta de dashboard jr010020

    {path: '*', element: <Navigate to="/auth/404"/>},
];

const filterRoutesToSave = (routes) => {
    return routes.filter(route => route.toSave === true).map(route => route.path.split(":")[0]);
};

const RoutesToSave = filterRoutesToSave(childrenRoutes);

const Router = [
    {
        path: '/',
        element: <FullLayout routesToSave={RoutesToSave}/>,
        children: childrenRoutes
    },
    {
        path: 'auth',
        element: <BlankLayout/>,
        children: [
            {path: '404', element: <Error/>},
            {path: 'login', element: <Login/>},
            {path: 'unauthorized', element: <Unauthorized/>},
            {path: '*', element: <Navigate to="/auth/404"/>},
        ],
    },
];

export default Router;
