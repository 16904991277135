const initialState = {
    loading: false,
    error: null,
    cards: [], // Todos los ítems
    visibleCards: [], // Ítems visibles
    startIndex: 0, // Índice de inicio de los ítems visibles
    endIndex: 24, // Índice final de los ítems visibles
  };
  
  export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_DASHBOARD_DATA':
        return { ...state, loading: true, error: null };
      case 'FETCH_DASHBOARD_DATA_SUCCESS':
        return {
          ...state,
          loading: false,
          cards: action.payload,
          visibleCards: action.payload.slice(0, 24), // Carga inicial de 24 ítems
          startIndex: 0,
          endIndex: 24,
        };
      case 'FETCH_DASHBOARD_DATA_FAILURE':
        return { ...state, loading: false, error: action.payload };
      case 'LOAD_MORE_CARDS_DOWN':
        const cardsToLoadDown = action.payload || 4; // Cantidad de tarjetas a cargar/eliminar
        const nextStartIndexDown = state.startIndex + cardsToLoadDown;
        const nextEndIndexDown = state.endIndex + cardsToLoadDown;
        return {
          ...state,
          visibleCards: state.cards.slice(nextStartIndexDown, nextEndIndexDown),
          startIndex: nextStartIndexDown,
          endIndex: nextEndIndexDown,
        };
      case 'LOAD_MORE_CARDS_UP':
        const cardsToLoadUp = action.payload || 4; // Cantidad de tarjetas a cargar/eliminar
        const nextStartIndexUp = Math.max(state.startIndex - cardsToLoadUp, 0);
        const nextEndIndexUp = state.endIndex - cardsToLoadUp;
        return {
          ...state,
          visibleCards: state.cards.slice(nextStartIndexUp, nextEndIndexUp),
          startIndex: nextStartIndexUp,
          endIndex: nextEndIndexUp,
        };
      default:
        return state;
    }
  };